const phoneMixin= {
    data(){
        return {
            showPhonCodes: false,
            phoneCodes: [],
        }
    },
    created() {
        this.fetchPhoneCodes().then(() => {
            this.showPhonCodes = true;
        });
    },
    methods:{
        fetchPhoneCodes() {
            return this.axios.get(this.$urls.portal.phoneCodes.url, { params: { cursor: 1 } })
                .then(response => {
                    this.phoneCodes = response.data.data;
                }).catch(() => {
                });
        },
    }
};

export default phoneMixin;