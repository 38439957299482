<template>
  <v-select
      ref="phonCodeSelect"
      :class="addClass"
      v-model="item_id"
      :options="items"
      :reduce="option=>option.id"
      @search:blur="!item_id ? customValidator.item_id = false : customValidator.item_id = true"
      :placeholder="getTranslation('selectFromList')"
      label="code"
      :clearable="false"
  >
    <template slot="option" slot-scope="option">
      <img v-lazy="option.flag_url" width="30" height="30">
      {{ option.code }}
    </template>
  </v-select>
</template>

<script>
export default {
  name: "PhoneCode",
  props: {
    addClass: {
      type: String,
      default: 'input-group-addon',
    },
    editItemId: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    items: Array
  },
  data() {
    return {
      item_id: '',
      timer: null,
      showSelect: false,
      customValidator: {
        item_id: true,
      }
    }
  },
  created() {
    if ( this.editItemId )
      this.item_id = this.editItemId;
  },
  watch: {
    'item_id'(id) {

      this.$emit('input', id);

    }
  },
  methods: {

    // We will call this method from parent component for check validity
    checkValidity() {
      if ( !this.item_id ) {
        this.customValidator.item_id = false;
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped>

</style>